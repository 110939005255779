<template>
  <section>
    <div id="pdf-content">
      <header>
        <!-- <table id="header" style="width: 100%">
          <tr>
            <td><img src="@/assets/logo/logo_surat.png" height="50pt" /></td>
            <td style="text-align: center; font-size: 11pt">
              <b>SURAT TUGAS</b>
            </td>
          </tr>
        </table> -->
      </header>
      <section id="surat">
        <!-- <div class="row mb-2">
          <div class="col-11 offset-1">
            <div class="row" id="kop">
              <div class="col-2 bb">
                <img src="@/assets/logo/logo_surat.png" alt="" />
              </div>
              <div class="col-10 bb">
                <h3 id="judul">
                  PEMERINTAH KABUPATEN BANDUNG <br />
                  DINAS PEKERJAAN UMUM DAN TATA RUANG <br />
                  UPTD PENERANGAN JALAN UMUM <br />
                </h3>
                <p id="alamat">
                  Jl. RAYA SOREANG-BANJARAN KM. 3 SOREANG TELP. 5892773-5892580 –
                  SOREANG
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="row" id="halaman">
          <div class="col">
            <p
              style="
                font-family: 'Times New Roman', Times, serif;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                overflow: hidden;
                text-decoration: underline;
              "
            >
            FORM PENGAMBILAN BARANG
            </p>

            <div id="content">
              <p style="text-align: center">
                Nomor : <span>{{ dataPenugasan.no_assignment }}</span>
              </p>
              <br />
              <center v-if="isAssignmentLoading">
                <br />
                <br />
                <vue-loading></vue-loading>
              </center>
              <br />
              <template v-if="!isAssignmentLoading">
                <p>
                  Tanggal : <span>{{ $moment(dataPenugasan.assignment_date).format("D MMMM YYYY") }}</span>
                </p>
                <p>Lokasi Pemeliharaan :
                  <p style="font-size: 15px;" v-for="(address, index) in dataPenugasan.assignment_complaints" :key="index">
                    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ index + 1}} . {{ address.complaint.address }}
                  </p>
                </p>
                
              </template>

              <center v-if="isLoading">
                <br />
                <br />
                <vue-loading></vue-loading>
              </center>
              <template v-if="!isLoading">
                <table class="table table-sm table-bordered border-set">
                  <thead>
                    <tr class="text-center">
                      <th class="border-set">No.</th>
                      <th class="border-set">Nama Barang</th>
                      <th class="border-set">Jumlah</th>
                      <th class="border-set">Keterangan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in dataItem.item_out_details" :key="index">
                      <td class="text-center border-set">{{ index + 1 }}.</td>
                      <td class="border-set" style="width: 50%">
                        {{ data.item.name }}
                      </td>
                      <td class="border-set">
                        {{ data.qty }}
                      </td>
                      <td class="border-set"> </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <p style="text-align: center">
                KOORDINATOR WILAYAH PEMELIHARA PJU
              </p>
              <br />
              <br />
              <br />
              <br />
              <p style="text-align: center">( &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;)</p>
              <p style="text-align: center">MENGETAHUI</p>
              <br /><br /><br />
              <div class="row">
                <div class="col-6 text-center">
                  <p style="font-weight: bold">KEPALA UPTD PJU,</p>
                  <br /><br />
                  <p style="font-weight: bold">
                    <span style="text-decoration: underline"
                      >FAHRIZAL SOPYAN, ST, M.AP</span
                    ><br />NIP. 19790424 200604 1 012
                  </p>
                </div>
                <div class="col-6 text-center">
                  <p style="font-weight: bold">KASUBAG TU UPTD PJU,</p>
                  <br /><br />
                  <p style="font-weight: bold">
                    <span style="text-decoration: underline"
                      >AHMAD GEOHANSA,ST., M.Tr.AP</span
                    ><br />NIP. 19850103 201101 1 006
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      id : null,
      dataPenugasan: null,
      dataItem: null,
      isAssignmentLoading: true,
      isLoading: true,
    };
  },
  methods: {
    getData() {
      var loading = this.$loading.show();
      this.$store.dispatch("assignment/detailAssignment", this.id).then((resp) => {
        loading.hide();
        this.dataPenugasan = resp.data.data;
        this.dataItem =this.dataPenugasan.item_out;
        setTimeout(() => {
          this.isAssignmentLoading = false;
          this.isLoading = false;
        }, 2000);
        setTimeout(function () {
          window.print();
        }, 5000);
      }).catch(e => {
        this.$toast.error(e);
        loading.hide();
      });
    }
  },
  computed: {
    computedData() {
      return this.dataPenugasan.map((item) => {
        return {
          ...item,
        }
      });
    }
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    this.getData()
  },
};
</script>
  
  
<style scoped>
  body {
    font-size: 9px;
  }
  
  #judul {
    color: black;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 28px;
    line-height: 1;
  }
  #alamat {
    color: black;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 1;
  }
  #kop {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 38px;
    padding-right: 19px;
    /* padding-bottom: 28px; */
    border-bottom: 11px double black;
  }
  #halaman {
    color: black;
    width: auto;
    height: auto;
    padding-top: 28px;
    padding-left: 113px;
    padding-right: 76px;
    padding-bottom: 28px;
    font-family: "Times New Roman", Times, serif;
  }
  img {
    color: black;
    height: 110px;
  }
  #content {
    color: black;
    text-align: justify;
    font-size: 20px;
  }
  .border-set {
    /* font-size: 20px; */
    border: 2px solid #000 !important;
  }
  p {
    color: black;
    margin: 5px;
  }
</style>

<style>
#pdf-content {
  width: calc(29.7cm - 2cm * 2);
  /* margin: 28pt 32pt 28pt 32pt;
        word-spacing: 1pt;
        word-break: page-break; */
  color: #000 !important;
}
#content-table {
  font-size: 8pt;
  /* border-top: 1pt solid black;
  border-left: 1pt solid black; */
  border-collapse: collapse;
}
#content-table th,
#content-table td {
  /* border-right: 1px solid black;
  border-bottom: 1px solid black; */
  border: 1px solid black;
  padding-left: 3pt;
  padding-right: 3pt;
}
#footer {
  font-size: 8pt;
}
table {
  white-space: normal !important;
}
</style>